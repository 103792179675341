import React from 'react'

import { MDXRenderer } from 'gatsby-plugin-mdx'

import { Container } from 'theme-ui'

import Layout from './layout'
import SEO from '../../components/seo'
import PostTitle from 'gatsby-theme-blog/src/components/post-title'
import PostDate from 'gatsby-theme-blog/src/components/post-date'
import PostHero from 'gatsby-theme-blog/src/components/post-hero'
import PostFooter from './post-footer'

const Post = ({ data: { post }, previous, next }) => (
  <Layout>
    <SEO
      title={post.title}
      description={post.excerpt}
      imageSource={
        post.socialImage
          ? post.socialImage?.childImageSharp?.fluid.src
          : post.image?.childImageSharp?.fluid.src
      }
      imageAlt={post.imageAlt}
    />
    <main>
      <Container
        sx={{
          pl: [3, null, null, 5],
          pr: [3, null, null, 5],
          pt: 2,
          pb: 3,
          maxWidth: '85%',
        }}
      >
        <article>
          <header>
            <PostHero post={post} />
            <PostTitle>{post.title}</PostTitle>
            <PostDate sx={{ fontSize: [1, 2, 3] }}>{post.date}</PostDate>
          </header>
          <section>
            <MDXRenderer>{post.body}</MDXRenderer>
          </section>
        </article>
      </Container>
    </main>
    <PostFooter {...{ previous, next }} />
  </Layout>
)

export default Post
