import React from 'react'
import { Link } from 'gatsby'
import { css, Styled, Flex } from 'theme-ui'

const PostFooter = ({ previous, next }) => (
  <footer
    css={css({
      mt: 4,
      pt: 3,
    })}
  >
    {(previous || next) && (
      <Flex
        as='ul'
        css={css({
          listStyle: `none`,
          padding: 0,
          alignContent: 'center',
          justifyContent: 'space-between',
        })}
      >
        <li>
          {previous && (
            <Styled.a as={Link} to={previous.slug} rel='prev'>
              ← Previous Post
            </Styled.a>
          )}
        </li>
        <li>
          {next && (
            <Styled.a as={Link} to={next.slug} rel='next'>
              Next Post →
            </Styled.a>
          )}
        </li>
      </Flex>
    )}
  </footer>
)

export default PostFooter
